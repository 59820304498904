import React, { useState, useEffect, useCallback } from "react";
import { ISignInPageContent } from "../../interfaces";
import Heading from "../../infrastructure/components/Heading";
// import FormLabeledInput from "../../infrastructure/components/FormComponents/FormLabeledInput";
import ButtonComponent from "../../infrastructure/components/ButtonComponent";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import {
  signIn,
  authStart,
  authEnd,
  setToken,
  setExpiredTime,
  setTokenInHeaders,
  setTokenExpired,
} from "../../api/actionCreators/authActionCreator";
import classNames from "classnames"
import InputMask from 'react-input-mask';
import {
  getIsAuthFetching,
  getAuthData,
} from "../../api/selectors/authSelector";
import {
  getToken,
  getAccessToken,
  getTokenInHeaders,
} from "../../api/selectors/tokenSelector";
import CircularProgress from "@mui/material/CircularProgress";
import { Redirect } from "react-router-dom";
import axios from "../../api/services/Api";
import {
  storeToken,
  storeUser,
  storeExpiredTime,
  storeRefreshToken,
} from "../../api/services/Auth";
import { Logo } from "../../assets/icons/logo";
import moment from "moment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { PersonIcon, KeyIcon, EyeIcon, LanguageIcon } from "finergy-kit";
import { getCurrentLanguage } from "../../api/selectors/languageSelector";
import { changeLanguage } from "../../api/actionCreators/mainActionCreator";
import { useTranslation } from "react-i18next";
import { otpInputs, onlyNumbersRegex, PHONE_MAX_LENGHT } from "../../constants/";
import { ArrowIcon } from "../../svg-icons/icon-arrow"
import StepOtp from "./step-otp"
import OtpInput from "react-otp-input";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { enqueueSnackbar } from "notistack";

import styles from "./signin-page.module.scss";

const SignInPageContent = (props: ISignInPageContent) => {
  const dispatch = useDispatch();

  // const {authCode } = useParams();
  // const authCode = code.get("authCode"); 

  const isAuthFetching = useSelector(getIsAuthFetching);
  const token = useSelector(getToken);
  const accessToken = useSelector(getAccessToken);
  const data = useSelector(getAuthData);
  const language = useSelector(getCurrentLanguage);
  const tokenInHeader = useSelector(getTokenInHeaders);
  const { t } = useTranslation();

  const [login, setLogin] = useState("");
  // const [password, setPassword] = useState("");
  // const [forgotPassword, setForgotPassword] = useState(false);
  const [authCodeFetching, setAuthCodeFetching] = useState(false);
  const [showOtpContent, setShowOtpContent] = useState(false);
  const [error, setError] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [invalidFields, setInvalidFields] = useState([])
  const [currentLanguage, setcurrentLanguage] = useState(language);
  const [loginConfirmationId, setLoginConfirmationId] = useState("");
  const [waitTimeToResend, setWaitTimeToResend] = useState("");

  const handleLanguageChange = (lang) => {
    setcurrentLanguage(lang);
    changeLanguage(lang);
  };

  // useEffect(() => {
  //   setInvalidFields([])
  // }, [login])

  useEffect(() => {
    token && accessToken && setRedirect(true);
  }, [token]);

  // const validateInput = useCallback(() => {
  //   const result = [];
  //   !login && result.push("login");

  //   return result.length > 0 ? result : null;
  // }, []);

  const onLogin = useCallback(() => {
    console.log("isAuthFetching", login)
    // const invalidFields = validateInput();
    // setInvalidFields(invalidFields)
    setError("")



    const phone = login.replace(/\-/g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/\ /g, "")
      .replace(/\+/g, "");



    const params = {
      phoneNumber: phone.slice(3),
    }
    dispatch(authStart())
    axios.post("/cap/api/v1/auth/login/init", { ...params })
      .then((res: any) => {
        const { data: { result } } = res

        if (res.status === 200 || res.status === 201) {

          if (result === "success") {
            // console.log("result555555555555", data, res.status)
            setWaitTimeToResend(res.data.waitTimeToResend)
            setLoginConfirmationId(res.data.loginConfirmationId)
            dispatch(authEnd())
            setShowOtpContent(true)
          } else if (result === "not_found") {
            setError("Merchant_not_found")
            dispatch(authEnd())
          } else if (result === "otp_limit_exceeded") {
            setError("Otp_limit_exceeded")
            dispatch(authEnd())
          }
          // console.log("000000000000000000000000")
        } else {
          console.log("1111111111111111111111")
          setError(t("Wrong_details"))
          dispatch(authEnd())
        }
      })
      .catch((error) => {
        // console.log("2222222222222222222")
        setError(t("Wrong_details"))
        dispatch(authEnd())
      });

  }, [dispatch, login]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.code === 'Enter' && !tokenInHeader) {
        event.preventDefault();
        onLogin()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [onLogin, tokenInHeader])

  const intFormatPhoneMaskValue = login.replace(/\D/g, "").length !== PHONE_MAX_LENGHT

  const renderSignInContainer = () => {
    return (
      <>
        <Heading headingFirst={t("Authentification")} />
        <Heading
          textRegular={t("Fill_field_above")}
          className={`${styles.mb_24} ${styles.mt_16} `}
        />
        <InputMask
          mask="(+373) 99-999-999"
          id="phoneNumberFiled"
          value={login}
          onChange={(e) => { setError(""); setLogin(e.target.value) }}
        >
          {() =>
            <TextField

              placeholder={t('Mobile_phone_number_label')}
              id="outlined-basic"
              className={`${styles.sign_in_input} ${styles.mb_24}`}
              variant="outlined"
              fullWidth
              label={t('Mobile_phone_number_label')}
              inputProps={{
                tabIndex: 1
              }}
            />
          }
        </InputMask>

        <ButtonComponent
          title={t("Next")}
          onClick={() => (isAuthFetching || intFormatPhoneMaskValue) ? {} : onLogin()}
          disabled={isAuthFetching || intFormatPhoneMaskValue}
          icon={
            (isAuthFetching) && <CircularProgress className="circular-progress" size={18} />
          }
          className={styles.login_btn}
        />

        {error && <div className={styles.error_container}>{t(error)}</div>}
      </>
    )
  }

  return (
    <div
      className={classNames({
        [`${styles.mainLoginSection}`]: true,
        [`${styles.short_auth}`]: authCodeFetching,
      })}
    >
      {authCodeFetching && <CircularProgress className="circular-progress" size={48} />}
      {redirect && <Redirect to="/app/transactions" />}
      <div className={styles.grid_wrapper} >
        {/* <div className={styles.bg_image_container}>
          <Logo />
        </div> */}

        <div className={styles.bg_image_container}>
          <div className={styles.blur_bg}></div>
          <Logo />
        </div>


        <div className={styles.signin_container}>
          {showOtpContent && <ArrowIcon onClick={() => setShowOtpContent(false)} />}
          <div className={styles.main_container}>
            <div className={styles.select_lang_container_parent}>
              <FormControl className={styles.select_lang_container}>
                {/* <InputLabel id="demo-simple-select-label">{t('Employment')}</InputLabel> */}
                <LanguageIcon />
                <Select
                  className="select-lang-selector"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentLanguage}
                  onChange={(e) => handleLanguageChange(e.target.value)}
                >
                  <MenuItem value={"ro"} key={0}>
                    RO
                  </MenuItem>
                  <MenuItem value={"ru"} key={1}>
                    RU
                  </MenuItem>
                  <MenuItem value={"en"} key={2}>
                    EN
                  </MenuItem>
                </Select>
              </FormControl>
            </div>


            {!showOtpContent ? renderSignInContainer() :
              <StepOtp
                phoneNumber={login}
                loginConfirmationId={loginConfirmationId}
                waitTimeToResend={waitTimeToResend}
              />}
          </div>

          <div className={styles.copyright}>
            © 2024 miaPOS. {t("All_rights_reserved")}.
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignInPageContent;
