import translationEN from "./locales/en/translation.json";
import translationRO from "./locales/ro/translation.json";
import translationRU from "./locales/ru/translation.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getPrefLang, isPrefLangPresent } from "../api/services/Prefs";

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    ro: {
        translation: translationRO
    },
    ru: {
        translation: translationRU
    }
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: isPrefLangPresent() ? getPrefLang() : "ro",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
