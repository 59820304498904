import classNames from "classnames";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import Pagination from "../../infrastructure/components/Pagination";
import Button from "../../infrastructure/components/Button";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { SearchIcon } from "../../svg-icons/icon-search";
import { PlusIcon } from "../../svg-icons/icon-plus";
import { CalendarIcon } from "../../svg-icons/icon-calendar";
import CircularProgress from "@mui/material/CircularProgress";

import { apiRequest } from "../../api/services/Api";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import {
  setChangeDate,
  setPageSize,
  setPeriod,
} from "../../api/actionCreators/mainActionCreator";
import {
  getDateFrom,
  getDateTo,
  getPeriod,
  getDisputPageSize,
} from "../../api/selectors/mainSelector";
import useDebounce from "../../hooks/useDebounce";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { getCurrentLanguage } from "../../api/selectors/languageSelector";

import {
  CUSTOM_PERIOD,
  FORMAT,
  LAST_MONTH,
  LAST_THREE_MONTHS,
  QUARTER,
  capitalize,
  filtersDisputes,
  pages,
} from "../../constants";
import "dayjs/locale/en";
import "dayjs/locale/ro";
import "dayjs/locale/ru";

import styles from "./disputs.module.scss";

// import { createServer } from "miragejs";
// import authHandler from "../../api/miragejs/handlers/authHandler";

// const config = require("../../config.json");

// createServer({
//   routes() {
//     authHandler(this, "http://localhost:3000/");
//     this.passthrough();
//   },
// });

const Disputs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const language = useSelector(getCurrentLanguage);

  const [isFetching, setIsFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState("all");
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [customPeriodDialogOpen, setCustomPeriodDialogOpen] = useState(false);
  const pageSize = useSelector(getDisputPageSize);
  const [showPagination, setShowPagination] = useState(false);

  console.log('pageSize', pageSize);

  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const period = useSelector(getPeriod);
  const dateTo = useSelector(getDateTo);
  const dateFrom = useSelector(getDateFrom);

  const [modalDateTo, setModalDateTo] = useState<Dayjs | string>(
    dayjs(dateTo).format(FORMAT)
  );
  const [modalDateFrom, setModalDateFrom] = useState<Dayjs | string>(
    dayjs(dateFrom).format(FORMAT)
  );

  const fetchDisputes = async () => {
    setIsFetching(true);
    const f = `&disputeStatus=${filter === "all" ? "" : filter}`;

    try {
      const { status, response } = await apiRequest(
        "get",
        `/cap/api/v1/disputes?offset=${
          currentPage * pageSize
        }&count=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}${f}`,
        null
      );
      if (status === 200 || status === 201) {
        const {
          data: {
            disputeList,
            pagination: { totalCount },
          },
        } = response;
        setRows(disputeList || []);
        setTotalCount(totalCount);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch disputes:", error);
    } finally {
      setIsFetching(false);
      setShowPagination(true);
    }
  };

  useDebounce(
    () => {
      fetchDisputes();
    },
    [
      searchText,
      currentPage,
      pageSize,
      filter,
      period,
      token,
      accessToken,
      dateTo,
      dateFrom,
    ],
    500
  );

  useEffect(() => {
    setShowPagination(false);
  }, [searchText, pageSize, filter, period, dateTo, dateFrom, currentPage]);

  // const fetchDisputes = useCallback(async (filter) => {
  //   setIsFetching(true);
  //   const f = filter === "all" ? " " : filter;

  //   console.log('filter', filter)
  //   try {
  //     const { status, response } = await apiRequest(
  //       "get",
  //       `/sap/api/v1/disputes?offset=${currentPage * pageSize
  //       }&count=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}&disputeStatus=${f}`,
  //       null
  //     );

  //     console.log("response", response);

  //     if (status === 200 || status === 201) {
  //       const {
  //         data: {
  //           disputeList,
  //           pagination: { totalCount },
  //         },
  //       } = response;
  //       setRows(disputeList || []);
  //       setTotalCount(totalCount);
  //     } else if (status === 400) {
  //       enqueueSnackbar(t("Error_400"), { variant: "error" });
  //     } else if (status === 401) {
  //       enqueueSnackbar(t("Error_401"), { variant: "error" });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //     } else if (status === 404) {
  //       enqueueSnackbar(t("Error_404"), { variant: "error" });
  //     } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
  //       enqueueSnackbar(t("Error_500"), { variant: "error" });
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch disputes:", error);
  //   } finally {
  //     setIsFetching(false);
  //     setShowPagination(true)
  //   }
  // }, [searchText, currentPage, pageSize]);

  const renderLicensesCells = () => {
    return [
      {
        field: "disputeId",
        headerName: t("Disp_Id"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/disputs/${row.disputeId}`}
              className={styles.no_underline}
            >
              {row.disputeId}
            </Link>
          );
        },
      },
      {
        field: "externalDisputeId",
        headerName: t("MIA_Id"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => {
          return row.externalDisputeId ? row.externalDisputeId : "-";
        },
      },
      {
        field: "title",
        headerName: t("Disp_title"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/disputs/${row.disputeId}`}
              className={styles.title_no_underline}
            >
              {row.title}
            </Link>
          );
        },
      },
      {
        field: "categoryDescription",
        headerName: t("Category"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return row.categoryDescription;
        },
      },
      {
        field: "whenCreated",
        headerName: t("Creation_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => {
          return moment(row.whenCreated).format("DD/MM/YYYY HH:mm");
        },
      },
      {
        field: "disputeStatus",
        headerName: t("Status"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return UseErrorLabel(row.disputeStatus, t);
        },
      },
      {
        field: "assignedTo",
        headerName: t("Disp_assigned_on"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return (
            <div className={styles.status_wraper}>
              {UseErrorLabel(row.assignedTo, t)}
            </div>
          );
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setCurrentPage(0);
    dispatch(setPageSize(pages.DISPUT_PAGE, size));
  };

  const selectThisQuarter = () => {
    dayjs.extend(quarterOfYear);
    const dateFrom = dayjs().startOf("day").startOf("quarter").format(FORMAT);
    const dateTo = dayjs().endOf("day").format(FORMAT);

    dispatch(setChangeDate("dateFrom", dateFrom));
    dispatch(setChangeDate("dateTo", dateTo));
  };

  const selectLastMonth = () => {
    const dateFrom = dayjs().startOf("day").subtract(31, "day").format(FORMAT);
    const dateTo = dayjs().endOf("day").format(FORMAT);

    dispatch(setChangeDate("dateFrom", dateFrom));
    dispatch(setChangeDate("dateTo", dateTo));
    setCurrentPage(0);
  };

  const selectLastThreeMonths = () => {
    const dateFrom = dayjs().startOf("day").subtract(90, "day").format(FORMAT);
    const dateTo = dayjs().endOf("day").format(FORMAT);
    dispatch(setChangeDate("dateFrom", dateFrom));
    dispatch(setChangeDate("dateTo", dateTo));
    setCurrentPage(0);
  };

  const selectCustomPeriod = () => {
    const dateFrom = dayjs(modalDateFrom).startOf("day").format(FORMAT);
    const dateTo = dayjs(modalDateTo).endOf("day").format(FORMAT);

    dispatch(setChangeDate("dateFrom", dateFrom));
    dispatch(setChangeDate("dateTo", dateTo));
    dispatch(setPeriod(4));
    setCustomPeriodDialogOpen(false);
    setCurrentPage(0);
  };

  const handlePeriodChange = (event) => {
    event.target.value !== 4 && dispatch(setPeriod(event.target.value));

    switch (event.target.value) {
      case LAST_MONTH:
        selectLastMonth();
        break;
      case LAST_THREE_MONTHS:
        selectLastThreeMonths();
        break;
      case QUARTER:
        selectThisQuarter();
        break;
      case CUSTOM_PERIOD:
        setCustomPeriodDialogOpen(true);
        break;
      default:
        selectLastMonth();
        break;
    }
  };

  const renderFilterItems = () => {
    const content = filtersDisputes.map((filterName) => {
      return (
        <button
          className={classNames({
            [`${styles.filter_button}`]: true,
            [`${styles.filter_button_active}`]: filter === filterName,
          })}
          onClick={() => {
            setFilter(filterName);
            setCurrentPage(0);
          }}
        >
          {t(`${capitalize(filterName)}_filter`)}
        </button>
      );
    });

    return content;
  };

  const renderCustmDialog = () => {
    return (
      <Dialog open={customPeriodDialogOpen}>
        <DialogTitle id="custom-period-dialog">
          {t("Custom_period")}
        </DialogTitle>
        <DialogContent className={styles.content_visible}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={language ?? "ro"}
          >
            <DatePicker
              label={t("Date_from")}
              value={dayjs(modalDateFrom)}
              onChange={(newValue) => {
                setModalDateFrom(newValue);
              }}
              disableFuture
              maxDate={dayjs(modalDateTo)}
              format={"DD/MM/YYYY"}
            />
          </LocalizationProvider>

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={language ?? "ro"}
          >
            <DatePicker
              label={t("Date_to")}
              value={dayjs(modalDateTo)}
              onChange={(newValue) => {
                setModalDateTo(newValue);
              }}
              minDate={dayjs(modalDateFrom)}
              format={"DD/MM/YYYY"}
              disableFuture
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            variant="primary"
            onClick={() => {
              selectCustomPeriod();
              setCurrentPage(0);
            }}
            label={t("Search")}
          />

          <Button
            variant="primary"
            onClick={() => setCustomPeriodDialogOpen(false)}
            label={t("Cancel")}
          />
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <AppMainLayout navBarTitle={t("Disputs")}>
      {renderCustmDialog()}
      {/* <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
        <InputLabel htmlFor="outlined-adornment-amount">
          {t("Search")}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          startAdornment={<SearchIcon />}
          label={t("Search")}
          value={searchText}
          onChange={(e) => {
            setCurrentPage(0);
            setSearchText(e.target.value);
          }}
        />
      </FormControl> */}

      <div className={styles.calendar_and_filters_container}>
        <FormControl className={styles.select_period}>
          <CalendarIcon />
          <label>{t("Period")}</label>
          <Select
            value={period}
            name="selected-period"
            onChange={handlePeriodChange}
          >
            <MenuItem value={LAST_MONTH}>{t("Last_month")}</MenuItem>
            <MenuItem value={LAST_THREE_MONTHS}>
              {t("Last_three_months")}
            </MenuItem>
            <MenuItem value={QUARTER}>{t("Quarter")}</MenuItem>
            <Divider />
            <MenuItem
              value={CUSTOM_PERIOD}
              onClick={() => setCustomPeriodDialogOpen(true)}
            >
              {t("Custom_period")}
            </MenuItem>
          </Select>
          <div className={styles.transactions_dates}>
            {dayjs(dateFrom).format("DD/MM/YYYY")} -{" "}
            {dayjs(dateTo).format("DD/MM/YYYY")}
          </div>
        </FormControl>

        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div>

      <DataTableComponent
        checkboxSelection={false}
        columns={renderLicensesCells()}
        isFetching={isFetching}
        idChangeValue={"disputeId"}
        {...{ rows }}
      />
      <div className={styles.pagination_container}>
        {!isFetching && showPagination ? (
          <Pagination
            onClick={paginationClick}
            onChangePageSize={pageSizeClick}
            total={totalCount}
            pageIndex={currentPage}
            pageSize={pageSize}
          />
        ) : (
          <CircularProgress className="circular-progress" size={18} />
        )}
      </div>

      {/* <div className={styles.customer_page_bottom_container}>
        <Link to="/app/disputs/new" className={styles.no_underline}>
          <Button
            variant="primary"
            icon={<PlusIcon />}
            label={t("New_disput")}
          />
        </Link>
      </div> */}
    </AppMainLayout>
  );
};

export default Disputs;
