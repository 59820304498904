

import React from 'react';

export const CustomersIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.9883 11.638C18.6483 11.638 19.9783 10.298 19.9783 8.638C19.9783 6.978 18.6483 5.638 16.9883 5.638C15.3283 5.638 13.9883 6.978 13.9883 8.638C13.9883 10.298 15.3283 11.638 16.9883 11.638ZM8.98828 11.638C10.6483 11.638 11.9783 10.298 11.9783 8.638C11.9783 6.978 10.6483 5.638 8.98828 5.638C7.32828 5.638 5.98828 6.978 5.98828 8.638C5.98828 10.298 7.32828 11.638 8.98828 11.638ZM8.98828 13.638C6.65828 13.638 1.98828 14.808 1.98828 17.138V18.638C1.98828 19.188 2.43828 19.638 2.98828 19.638H14.9883C15.5383 19.638 15.9883 19.188 15.9883 18.638V17.138C15.9883 14.808 11.3183 13.638 8.98828 13.638ZM16.9883 13.638C16.6983 13.638 16.3683 13.658 16.0183 13.688C16.0383 13.698 16.0483 13.718 16.0583 13.728C17.1983 14.558 17.9883 15.668 17.9883 17.138V18.638C17.9883 18.988 17.9183 19.328 17.8083 19.638H22.9883C23.5383 19.638 23.9883 19.188 23.9883 18.638V17.138C23.9883 14.808 19.3183 13.638 16.9883 13.638Z" fill="#A1ABB2" />
        </svg>
    );
};