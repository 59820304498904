/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination";
import Button from "../../infrastructure/components/Button";
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PlusIcon } from "../../svg-icons/icon-plus";
import classNames from "classnames"
import styles from "./sellers.module.scss";
import { setPageSize } from "../../api/actionCreators/mainActionCreator";
import { getSellersPageSize } from "../../api/selectors/mainSelector";
import { pages, filtersSellers, capitalize, getModalText } from "../../constants";
import { UseErrorLabel } from "../../hooks/useErrorLabel"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useDebounce from '../../hooks/useDebounce';
import { enqueueSnackbar } from "notistack";
import { apiRequest } from "../../api/services/Api";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import { TransitionProps } from "@mui/material/transitions";
import Select from "@mui/material/Select";
import NotificationItem from "../../infrastructure/components/NotificationItem"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Sellers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const [isFetchStores, setIsFetchStores] = useState(true);
  const [searchText, setSearchText] = useState("");
  // const [redirectId, setRedirectId] = useState(null);
  const [sellerStatus, setSellerStatus] = useState("all");
  const [actionState, setActionState] = useState("");
  const [actionRowId, setActionRowId] = useState("");
  const [actionRowIdFullName, setActionRowIdFullName] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [selectedOldStoreId, setSelectedOldStoreId] = useState(null);
  const [showPagination, setShowPagination] = useState(false);

  const [storeList, setStoreList] = useState([]);
  const [isActionStateFetching, setIsActionStateFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rows, setRows] = useState([]);
  //   const [filter, setFilter] = useState("all");
  const [totalCount, setTotalCount] = useState(0);

  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const pageSize = useSelector(getSellersPageSize);

  useEffect(() => {
    if (actionState === 'transfer') {
      const fetchStores = async () => {
        setIsFetchStores(true)
        try {
          const { status, response } = await apiRequest(
            "get",
            `/cap/api/v1/store/brief`,
            null
          );

          if (status === 200 || status === 201) {
            const {
              data: {
                storeList,
              },
            } = response;

            setStoreList(storeList || []);
          } else if (status === 400) {
            enqueueSnackbar(t("Error_400"), { variant: "error" });
          } else if (status === 401) {
            enqueueSnackbar(t("Error_401"), { variant: "error" });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else if (status === 404) {
            enqueueSnackbar(t("Error_404"), { variant: "error" });
          } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
            enqueueSnackbar(t("Error_500"), { variant: "error" });
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetchStores(false);
          setShowPagination(true)
        }
      }

      fetchStores()
    }
  }, [actionState])


  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // setAnchorEl(null)
          setTimeout(() => {
            setAnchorEl(null)
          }, 200);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const fetchSellers = useCallback(async (state = "all", searchText = "", pageSize, currentPage) => {
    setIsFetching(true);
    try {

      const f = state === "all" ? " " : state;
      const { status, response } = await apiRequest(
        "get",
        `/cap/api/v1/seller?offset=${currentPage * pageSize
        }&count=${pageSize}&filter=${searchText}&status=${f}`,
        null
      );

      if (status === 200 || status === 201) {
        const {
          data: {
            userList,
            paginationInfo: { totalCount },
          },
        } = response;

        setRows(userList || []);
        setTotalCount(totalCount);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch sellers:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useDebounce(() => {
    fetchSellers(sellerStatus, searchText, pageSize, currentPage)
  }, [searchText, currentPage, pageSize, token, accessToken, sellerStatus], 500);

  useEffect(() => {
    setShowPagination(false)
  }, [searchText, pageSize, sellerStatus, currentPage])

  const handleOpen = (id) => {
    setAnchorEl(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  const onSellerAction = async ({ method, apiUrl, url, locale, localeOther }) => {
    setIsActionStateFetching(true)
    const params = {
      storeId: selectedStoreId
    }
    try {
      const { status } = await apiRequest(
        method,
        apiUrl,
        selectedStoreId ? { ...params } : null
      );

      if (status === 200 || status === 201 || status === 204) {
        enqueueSnackbar(<NotificationItem
          id={actionRowId}
          text={actionRowIdFullName}
          {...{ url, locale, localeOther }}
        />, { variant: "success" });
        fetchSellers(sellerStatus, searchText, pageSize, currentPage)
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
      setIsActionStateFetching(false);
    } catch (error) {
      console.error(error);
    } finally {

      setActionRowId("")
      setActionRowIdFullName("")
      setActionState("")
      setSelectedStoreId(null)
      setSelectedOldStoreId(null)
    }
  }

  const renderActionModal = () => {
    const textLabels = getModalText(actionState, actionRowId, t)

    // const ifNeedToDisable = actionState === "transfer" ? selectedStoreId ===  : false 

    // actionState !== "restore" && actionState !== "suspend" && actionState !== "Delete"
    const checkStores = actionState === "transfer" ? selectedStoreId === selectedOldStoreId : false
    return (
      <Dialog
        open={!!actionState}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setActionState("")}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {textLabels.title}
          </DialogTitle>
          {actionState === "transfer" ?

            <DialogContent className={styles.modal_content}>
              <DialogContentText
                id="alert-dialog-slide-description"
                className={styles.logout_header}
              >
                <div
                  className={classNames({
                    [styles.info_block_input_container]: true,
                  })}
                >
                  <label htmlFor="merchantId">{t("Store_id")}</label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("Store_id")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="merchantId"
                      value={selectedStoreId}
                      label={t("Store_id")}
                      onChange={(e) =>
                        setSelectedStoreId(e.target.value)
                      }
                    >
                      {storeList.map((i) => (
                        <MenuItem value={i.id}>
                          #{i.id} / {i.storeName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

              </DialogContentText>
            </DialogContent>
            :
            <DialogContent className={styles.modal_content}>
              <DialogContentText
                id="alert-dialog-slide-description"
                className={styles.logout_header}
              >
                {textLabels.description} #{actionRowId}
              </DialogContentText>
            </DialogContent>}



          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => isActionStateFetching ? {} : onSellerAction(textLabels)}
              label={textLabels.btnLabel}
              variant={actionState === "delete" ? "delete" : "ghost"}
              disabled={isActionStateFetching || checkStores}
              className={classNames({
                [styles.disabled_btn]: isActionStateFetching || checkStores,
                [`${styles[`btn_${actionState}`]}`]: true,
              })}
              // iconPosition="off"
              icon={
                isActionStateFetching && <CircularProgress className="circular-progress" size={18} />
              }
            />
            <Button
              onClick={() => setActionState("")}
              variant="ghost"
              label={t("Cancel")}
            // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const sellersSetActions = (action, { id, storeId, fullName }) => {
    setActionState(action)
    setActionRowId(id)
    setActionRowIdFullName(fullName)
    const sId = action === "transfer" ? storeId : null
    setSelectedStoreId(sId)
    setSelectedOldStoreId(sId)
  }

  const renderActionDotts = (row) => {
    const { id, status } = row
    return (
      <div style={{ position: "relative", cursor: "pointer" }} ref={anchorEl !== null ? wrapperRef : null}>
        <MoreVertIcon
          onClick={() => handleOpen(id)}
          aria-labelledby={`demo-positioned-button-${id}`}
          id={`fade-button-${id}`}
          aria-controls={anchorEl === id ? `fade-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl === id ? 'true' : undefined}
        />
        <div

          className={classNames({
            [`${styles.menu_actions}`]: true,
            [`${styles.show}`]: anchorEl === id,
          })}
        >
          <div >
            <Link
              to={`/app/sellers/${id}`}
              className={styles.no_underline_link}
            >
              {t('Edit')}
            </Link>
          </div>
          <div onClick={() => sellersSetActions("transfer", row)}>{t('Transfer')}</div>
          {status === "suspended" ?
            <div onClick={() => sellersSetActions("restore", row)}>{t('Restore')}</div> :
            <div onClick={() => sellersSetActions("suspend", row)}>{t('Suspend')}</div>}

          <div onClick={() => sellersSetActions("delete", row)}>{t('Delete')}</div>
        </div>
      </div>

      // <MoreVertIcon />
    )
  }
  const renderLicensesCells = () => {
    return [
      {
        field: "id",
        headerName: t("Seller_id"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/sellers/${row.id}`}
              className={styles.no_underline}
            >
              {row.id}
            </Link>
          );
        },
      },
      {
        field: "fullName",
        headerName: t("Name"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/sellers/${row.id}`}
              className={styles.no_underline}
            >
              {row.fullName}
            </Link>
          );
        },
      },
      {
        field: "storeName",
        headerName: t("Store_name"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/stores/${row.storeId}`}
              className={styles.no_underline}
            >
              {row.storeName}
            </Link>
          );
        },
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          console.log("row.status", row.status)
          return UseErrorLabel(row.status, t);
        },
      },
      {
        field: "registrationDate",
        headerName: t("Registration_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return moment(row.registrationDate).format("DD-MM-YYYY HH:mm");
        },
      },

      {
        field: "actions",
        headerName: "#",
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return renderActionDotts(row);
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    console.log("page", page)
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setIsFetching(true);
    setCurrentPage(0);
    dispatch(setPageSize(pages.SELLERS_PAGE, size));
  };

  const renderFilterItems = () => {
    const content = filtersSellers.map((sellerFilter) => {
      return (
        <button
          className={classNames({
            [`${styles.filter_button}`]: true,
            [`${styles.filter_button_active}`]: sellerStatus === sellerFilter,
          })}
          onClick={() => { setCurrentPage(0); setSellerStatus(sellerFilter) }}
        >
          {t(`${capitalize(sellerFilter)}`)}
        </button>
      );
    });

    return content;
  };

  return (
    <AppMainLayout navBarTitle={t("Sellers")}>
      {/* {(redirectId === 0 || redirectId > 0) && (
        <Redirect to={`/app/merchants/${redirectId}`} />
      )} */}
      {!!actionState && renderActionModal()}


      <div className={styles.customer_page_bottom_container}>
        <FormControl fullWidth >
          <InputLabel htmlFor="outlined-adornment-amount">
            {t("Search")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<SearchIcon />}
            label={t("Search")}
            value={searchText}
            onChange={(e) => { setCurrentPage(0); setSearchText(e.target.value) }}
          />
        </FormControl>

        <Link to="/app/sellers/new" className={styles.no_underline}>
          <Button
            variant="primary"
            icon={<PlusIcon />}
            label={t("New_seller")}
            disabled={isFetching}
          />
        </Link>
      </div>
      <div className={styles.calendar_and_filters_container}>
        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div>

      <DataTableComponent
        // onCellClick={(row) => onCellClick(row)}
        checkboxSelection={false}
        columns={renderLicensesCells()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.sellers_table}
        {...{ rows }}
      />
      {!isFetching && (
        <Pagination
          onClick={paginationClick}
          onChangePageSize={pageSizeClick}
          total={totalCount}
          pageIndex={currentPage}
          pageSize={pageSize}
        />
      )}


    </AppMainLayout>
  );
};

export default Sellers;
