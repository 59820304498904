import React from "react";
import { IButtonComponentProps } from "./types";
import classNames from "classnames";
import styles from "./button.module.scss";

function Button({
  label,
  disabled,
  onClick,
  children,
  className,
  icon,
  variant, // primary, ghost, delete,
  iconRight = false
}: IButtonComponentProps) {
  const buttonClasses = classNames({
    [styles[`btn-${variant}`]]: true,
    [className]: className,
    [styles.labelCentered]: !icon,
    [styles.disabled]: disabled || true,
    [styles.common]: true
    // [styles.isLoading]: isLoading,
    // [iconPosition && styles[`icon-${iconPosition}`]]: !iconOnly,
  });
  return (
    <button className={buttonClasses} onClick={onClick} disabled={disabled}>
      {icon && !iconRight && <div className="icon-container">{icon}</div>}
      <span> {label} </span>
      {iconRight && <div className="icon-container">{icon}</div>}
    </button>
  );
}

export default Button;
