import React, { useEffect, useState } from "react";
import SignInPage from "./pages/SignInPage";
// import Dashboard from "./pages/Invoices";
import Transactions from "./pages/Transactions";
import TransactionsDetails from "./pages/TransactionsDetails";
import Sellers from "./pages/Sellers";
import SellerDetails from "./pages/SellerDetails";
import Terminals from "./pages/Terminals";
import TerminalDetails from "./pages/TerminalDetails";
import Stores from "./pages/Stores";
import StoreDetails from "./pages/StoreDetails";
import Disputs from "./pages/Disputs";
import DisputDetails from './pages/DisputDetails';
import RTP from "./pages/RTP";
import RTPDetails from "./pages/RTPDetails";
// import AddOrder from "./pages/AddOrder";
// import CompaniesPage from "./pages/CompaniesPage";
import { Route, Switch, Redirect } from "react-router-dom";
import NotificationItem from "./infrastructure/components/TopNavBarComponent/notification-modal/notification-item";
import { useSelector, useDispatch } from "react-redux";
import {
  getToken,
  getAccessToken,
  getTokenInHeaders,
} from "./api/selectors/tokenSelector";
import {
  removeToken,
  removeUserData,
  removeExpiredTime,
  removeRefreshToken,
} from "./api/services/Auth";
import {
  logoutFinish,
  setToken,
  setExpiredTime,
  signIn,
  setTokenInHeaders,
} from "./api/actionCreators/authActionCreator";
import { getLocalstorageToken, isTokenPresent } from "./api/services/Auth";
import axios from "axios";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { enqueueSnackbar } from "notistack";

import "./App.scss";

const App = () => {
  const dispatch = useDispatch();

  const token = useSelector(getToken);
  const accessToken = useSelector(getAccessToken);
  const tokenInHeader = useSelector(getTokenInHeaders);
  const tokenFromLocalStorage = isTokenPresent() ? getLocalstorageToken() : "";

  useEffect(() => {
    // refreshToken()
    if (!token && !accessToken) {
      removeExpiredTime();
      removeRefreshToken();
      dispatch(logoutFinish());
      dispatch(setToken({ accessToken: "", refreshToken: "", expiresIn: "" }));
      dispatch(setExpiredTime(null));
      dispatch(signIn(null));
      removeToken();
      removeUserData();
    } else {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + tokenFromLocalStorage;
      dispatch(setTokenInHeaders(true));
    }
  }, [token]);

  return (
    <Switch>
      <Route path="/" exact component={SignInPage} />
      <Route
        path="/app/transactions/:txId"
        exact
        component={TransactionsDetails}
      />
      <Route path="/app/transactions" exact component={Transactions} />

      <Route path="/app/stores/:storeId" exact component={StoreDetails} />
      <Route path="/app/stores" exact component={Stores} />

      <Route path="/app/terminals" exact component={Terminals} />
      <Route
        path="/app/terminals/:terminalId"
        exact
        component={TerminalDetails}
      />

      <Route path="/app/sellers" exact component={Sellers} />
      <Route path="/app/sellers/:sellerId" exact component={SellerDetails} />

      <Route path="/app/disputs" exact component={Disputs} />
      <Route
        path="/app/disputs/:disputId"
        exact
        render={({ match }) => (
          <DisputDetails disputeId={match.params.disputId} />
        )}
      />
      <Route
        path="/app/requests/:rtpId"
        exact
        component={RTPDetails}
      />
      <Route path="/app/requests" exact component={RTP} />

      {/* <Route path="/app/kyc-data" exact component={KycData} /> */}
      {/* <Route path="/app/orders" exact component={Order} />
      <Route path="/app/add-order" exact component={AddOrder} />
      <Route path="/app/order/details/:orderId" exact component={AddOrder} />
      <Route path="/app/trades" exact component={Licenses} /> */}
      {/* <Route path="/app/licenses/:customerId" exact component={CustomerLicenses} /> */}
      {/* <Route path="/app/company" exact component={CompaniesPa ge} /> */}
      {/* <Route path="/app/customers/info/:customerId" exact component={CustomerAddEdit} />
            <Route path="/app/customers/new" exact component={CustomerAddEdit} /> */}
      <Redirect to="/app/transactions" />
    </Switch>
  );
};

export default App;
