import React from "react";

export const ExitIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.3333 14.1666L17.5 9.99997M17.5 9.99997L13.3333 5.8333M17.5 9.99997H7.5M10 14.1666C10 14.413 10 14.5361 9.99085 14.6428C9.89569 15.7516 9.07905 16.664 7.98753 16.881C7.88252 16.9019 7.76001 16.9155 7.51529 16.9427L6.66412 17.0373C5.3854 17.1794 4.74601 17.2504 4.23805 17.0879C3.56078 16.8711 3.00785 16.3762 2.71765 15.727C2.5 15.2401 2.5 14.5968 2.5 13.3102V6.68972C2.5 5.40311 2.5 4.7598 2.71765 4.2729C3.00785 3.6237 3.56078 3.12881 4.23805 2.91208C4.74601 2.74953 5.38538 2.82057 6.66412 2.96265L7.51529 3.05723C7.7601 3.08443 7.8825 3.09803 7.98753 3.11891C9.07905 3.33593 9.89569 4.24834 9.99085 5.35714C10 5.46383 10 5.58699 10 5.8333"
        stroke="#96A0AA"
        stroke-width="2.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};