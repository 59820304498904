import moment from "moment"
import { Link } from "react-router-dom";

export const LAST_MONTH = 1;
export const LAST_THREE_MONTHS = 2;
export const QUARTER = 3;
export const CUSTOM_PERIOD = 4;
export const LAST_HOUR = 5;
export const LAST_DAY = 6;

export const pages = {
    TERMINAL_PAGE: "terminalPage",
    MERCHANT_PAGE: "merchantPage",
    TRANSACTION_PAGE: "transactionPage",
    SELLERS_PAGE: "sellersPage",
    DISPUT_PAGE: "disputPage",
}

export const filterData = [
    { name: "all", showInDropDown: false },
    { name: "new", showInDropDown: true },
    { name: "invoiced", showInDropDown: true },
    { name: "overdue", showInDropDown: true },
    { name: "payed", showInDropDown: true },
    { name: "canceled", showInDropDown: true },
]

export const filters = ["all", "active", "completed", "deleted", "in_process", "expired", "error"]

export const filtersTransactions = ["all", "success", "error", "partial_revert", "revert"]
export const filtersSellers = ["all", "online", "offline", "suspended"]
export const filtersTerminals = ["all", "attached", "unattached", "activated", "opened_shift", "closed_shift"]
export const filtersDisputes = ["all", "discussion", "approved", "closed"]
export const filtersRTP = ["all", "created", "pending", "paid", "expired", "canceled", "denied","error"]

const LOGO_MAX_SIZE = 10 * 1024 * 1024; // 10 Mb

export const isImageValid = (file, t, enqueueSnackbar) => {

    if (!file || !t) {
        return false;
    }

    // const fileType = file.type;
    const fileSize = file.size;

    // if (!LOGO_TYPES.includes(fileType)) {
    //     alert(t("Invalid_file_type"));
    //     return false;
    // }

    if (fileSize > LOGO_MAX_SIZE) {
        enqueueSnackbar(t("Max_logo_size"), { variant: "error" });
        return false;
    }

    return true;
};

export const FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const generateToken = () => {
    let token = "";
    const possible = "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

    for (var i = 0; i < 50; i++)
        token += possible.charAt(Math.floor(Math.random() * possible.length));

    return token;
}

export const amountFormatter = (number) => Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

// format as ### ###
export const numberFormatter = (number) => Number(number).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$& ');

export const checkDisabledButton = (uploadedData, customerInfo) => {
    const disabled =
        uploadedData.commissionInfo.buyCommission.toString() === customerInfo.commision.values.buyCommission.toString() &&
        uploadedData.commissionInfo.sellCommission.toString() === customerInfo.commision.values.sellCommission.toString() &&
        uploadedData.companyInfo.comment === customerInfo.general.values.description &&
        uploadedData.companyInfo.companyName === customerInfo.general.values.companyName &&
        uploadedData.companyInfo.idno === customerInfo.general.values.idno &&
        uploadedData.directorInfo.directorFullName === customerInfo.users.values.directorNameSurname &&
        uploadedData.directorInfo.idnp === customerInfo.users.values.directorIdnp &&
        uploadedData.directorInfo.phone.toString() === customerInfo.users.values.directorPhone.toString();

    return disabled
}


export const checkIfTokenExpired = (expiredTime) => {
    const now = moment()
    const secDiff = now.diff(expiredTime, "seconds");

    const res = (secDiff * -1) < 20 ? true : false
    return res
}

export const VALIDATION = {
    SIGN_IN_FORM: ["login", "password"],
    ADD_SELLER_FORM: ["fullName", "storeId", "phoneNumber", "email", "username", "password"],
    ADD_STORE_FORM: ["storeName", "storeAddress", "phoneNumber", "email", "username", "password"]
}

export const otpInputs = [1, 2, 3, 4, 5, 6];

export const onlyNumbersRegex = (value) => {
    const regex = /^[0-9]+$/
    return regex.test(value)
}

const getRoundedNumber = (amount, number, letter) => {
    let result
    if (amount % number === 0) {
        result = `${(amount / number).toFixed(0)}${letter}`;
    } else {
        result = `${(amount / number).toFixed(1)}${letter}`;
    }

    return result
}

export const getShortAmount = (amount) => {
    let text
    if (amount > 0 && amount <= 999) {
        text = amount;
    } else if (amount >= 1000 && amount <= 999999) {
        text = getRoundedNumber(amount, 1000, "K");
    } else if (amount >= 1000000 && amount <= 999999999) {
        text = getRoundedNumber(amount, 1000000, "M");;
    } else if (amount > 0) {
        text = getRoundedNumber(amount, 1000000000, "B");;
    }

    return text
}

export const generatePassword = () => {
    var pass = "";
    var possible = "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

    for (var i = 0; i < 20; i++)
        pass += possible.charAt(Math.floor(Math.random() * possible.length));

    return pass;
}

const renderNotifUrl = (id, text, url, locale) => {
    return (
        <>
            {text}
            <Link to={`${url}${id}`}>
                #{id}
            </Link>
        </>
    );
}

export const getModalText = (state, actionRowId, t) => {
    let text

    switch (state) {
        case "change":
            text = {
                title: t("Confirm_password_change"),
                description: t("Confirm_password_change_description"),
                btnLabel: t("Change"),
                method: "post",
                apiUrl: ``,
                url: "",
                locale: "",
                localeOther: "",
                postFix: false
            }
            break;

        case "restore":
            text = {
                title: t("Confirm_user_restore"),
                description: t("Confirm_user_restore_description"),
                btnLabel: t("Restore"),
                method: "post",
                apiUrl: `/cap/api/v1/seller/${actionRowId}/restore`,
                url: "/app/sellers/",
                locale: "Notification_restore_user",
                localeOther: "",
                postFix: false
            }
            break;
        case "delete":
            text = {
                title: t("Confirm_seller_delete"),
                description: t("Confirm_seller_delete_description"),
                btnLabel: t("Delete"),
                apiUrl: `/cap/api/v1/seller/${actionRowId}`,
                method: "delete",
                url: "/app/sellers/",
                locale: "Notification_delete_user",
                localeOther: "",
                postFix: false
            }
            break;

        case "suspend":
            text = {
                title: t("Confirm_user_suspend"),
                description: t("Confirm_user_suspend_description"),
                btnLabel: t("Suspend"),
                apiUrl: `/cap/api/v1/seller/${actionRowId}/suspend`,
                method: "post",
                url: "/app/sellers/",
                locale: "Notification_suspend_user",
                localeOther: "",
                postFix: false
            }
            break;

        case "assign":
            text = {
                title: t("Confirm_user_assign"),
                description: t("Confirm_user_assign_description"),
                btnLabel: t("Assign"),
                apiUrl: `/cap/api/v1/pos/${actionRowId}/store/assign`,
                method: "post",
                url: "",
                locale: "Notification_assign_terminal",
                localeOther: "Notification_assign_terminal_other",
                postFix: true
            }
            break;
        case "unassign":
            text = {
                title: t("Confirm_user_unassign"),
                description: t("Confirm_user_unassign_description"),
                btnLabel: t("Confirm"),
                apiUrl: `/cap/api/v1/pos/${actionRowId}/store/unassign`,
                method: "post",
                url: "",
                locale: "Notification_unassign_terminal",
                localeOther: "Notification_unassign_terminal_other",
                postFix: false
            }
            break;
        default:

            text = {
                title: t("Transfer_seller"),
                description: "",
                btnLabel: t("Save"),
                apiUrl: `/cap/api/v1/seller/${actionRowId}/store/transfer`,
                method: "post",
                url: "/app/sellers/",
                locale: "Notification_confirm_user_transfer",
                localeOther: "Notification_other_transfer", 
                postFix: false
            }
            break;
    }

    return text
}
// id={actionRowId} url={"/app/sellers/"} fullName={fullName}
export const filterKycData = ["all", "filling", "filled", "bank received", "abandoned", "error"]


export const PHONE_MAX_LENGHT = 11
export const mockIdnp = "0982207017934"
export const TIMER_RESEND_OTP = 60
export const CONFIRM_SMS = {
    VERIFIED: "verified",
    INVALID_OTP: "invalid_otp",
    NO_ATTEMPTS_LEFT: "Otp_limit_exceeded",
    OTP_EXPIRED: "otp_expired",
    OTP_NOT_FOUND: "not_found",
}
export const CONFIRM_BULETIN = {
    OK: "ok",
    FILE_TOO_BIG: "file_too_big",
    IDNP_NOT_MATCHED: "idnp_not_matched",
    TOO_MANY_ATTEMPTS: "too_many_attempts",
    FILE_ERROR: "file_error",
    ALREADY_STORED: "already_stored",
}
export const RESEND_SMS = {
    SUCCESS: "success",
    NO_ATTEMPTS_LEFT: "no_attempts_left",
    TO_EARLY: "too_early",
    OTP_NOT_FOUND: "otp_not_found",
}