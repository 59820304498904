/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios, { apiRequest } from "../../api/services/Api";
import { useTranslation } from "react-i18next";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import classNames from "classnames";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { getAccessToken } from "../../api/selectors/tokenSelector";
import styles from "./disput-details.module.scss";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { isImageValid } from "../../constants";
import { DownloadIcon } from "../../svg-icons/icon-download"
import Button from "@mui/material/Button";
import { CornerBankIcon } from "../../svg-icons/icon-corner-bank";
import { CornerMyIcon } from "../../svg-icons/icon-corner-my";
import { DisputsIcon } from "../../svg-icons/icon-disputs";
import { SendIcon } from "../../svg-icons/icon-send-message";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { AttachFileClipIcon } from "../../svg-icons/icon-attach-file-clip";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DisputDetails = ({ disputeId }) => {
  const { t } = useTranslation();
  // const { disputeId } = useParams();

  console.log("disputeId", disputeId);
  const defaultDesputeInfo = {
    disputeId: null,
    title: "",
    disputeDescription: "",
    externalDisputeId: "",
    categoryDescription: "",
    reasonDescription: "",
    whenCreated: "",
    merchantId: null,
    merchantName: "",
    disputeStatus: "",
    assignedTo: "",
    paymentInfo: {
      swiftMessageId: "",
      swiftMessageType: "",
      amount: null,
      ccy: "",
      txId: "",
    },
  };

  const [isFetching, setIsFetching] = useState(true);
  const [disputeDetail, setdisputeDetail] = useState({ ...defaultDesputeInfo });
  const [redirect, setRedirect] = useState(false);

  const [assignToMerchantModal, setAssignToMerchantModal] = useState(false);
  const [markDecisionModal, setMarkDecisionModal] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [disputeMessages, setDisputeMessages] = useState([]);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const [lastMessageId, setLastMessageId] = useState("");
  const [disputeNewMessages, setDisputeNewMessages] = useState([]);
  const [fetchMessages, setFetchMessages] = useState(false);
  const [fireScroll, setFireScroll] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [modalFetching, setModalFetching] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [fullFile, setFullFile] = useState(null);

  const [image, setImage] = useState({
    src: null,
    name: "",
    allFile: null
  });

  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    const onScroll = () => {
      const div = document.getElementById('messagesContainer');
      div?.scrollTo({
        top: div.scrollHeight,
        behavior: 'smooth',
      })

      setFireScroll(false)
    }
    fireScroll && onScroll()
  }, [fireScroll])

  useEffect(() => {
    const fetchDisputesInfo = async () => {
      setIsFetching(true);
      try {
        const { status, response } = await apiRequest(
          "get",
          `/cap/api/v1/dispute/${disputeId}`,
          null
        );

        if (status === 200 || status === 201) {
          const { data } = response;
          setdisputeDetail(data);
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      } catch (error) {
        console.error("Failed to fetch transaction details:", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchDisputesInfo();
  }, [accessToken, disputeId]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.code === 'Enter' && messageText.length > 0) {
        event.preventDefault();
        image.name ? onUploadFile() : sendMessage()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [messageText, image])

  const downloadFile = async (fileId, fileName) => {

    axios.get(`/cap/api/v1/file/${fileId}`, {
      responseType: 'blob' // Важно указать тип ответа как 'blob'
    }).then(res => {
      const { data, status } = res;


      if (status === 200 || status === 201) {

        const imageUrl = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = imageUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);

      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });

      }
    })
  }


  const renderDisputeMessages = () => {
    const content = disputeMessages.map(message => {
      const { isBankMessage, messageText, fileName, fileId, whenCreated } = message
      return (
        <div className={classNames({
          [styles.message_bubble]: true,
          [styles.message_bubble_is_bank]: !isBankMessage,
        })}

        >


          {isBankMessage && <CornerBankIcon />}
          <div>
            {messageText}
            <div className={styles.message_data}>
              {moment(whenCreated).format("DD/MM/YYYY HH:mm")}
            </div>
            {fileName && <div className={styles.hr} />}
            {fileName &&
              <span className={styles.download_file}>{fileName}
                <b onClick={() => downloadFile(fileId, fileName)}><DownloadIcon /></b>
              </span>
            }
          </div>
          {!isBankMessage && <CornerMyIcon />}

        </div>
      )
    })
    return content;
  }


  const fetchDisputeMessages = () => {
    setIsFetchingMessages(true);

    axios.get(`/cap/api/v1/dispute/${disputeId}/messages?messageId=${lastMessageId}`).then(res => {
      const { data, status } = res;
      setIsFetchingMessages(false);
      setShowChat(true);

      if (status === 200 || status === 201) {
        // console.log("data?.messageList", data?.messageList)
        // setDisputeMessages(data?.messageList);

        if (lastMessageId !== "") {
          setDisputeMessages([
            ...disputeMessages,
            // ...disputeNewMessages, 
            ...data?.messageList
          ]);
          setDisputeNewMessages(data?.messageList);
        } else {
          console.log("data?.messageList", data?.messageList)
          setDisputeMessages(data?.messageList);
        }

        data?.messageList.length > 0 && setLastMessageId(data.messageList[data.messageList.length - 1].messageId)
        setFetchMessages(false)
        setFireScroll(true)
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        // setTimeout(() => {
        //   setRedirect(true);
        // }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        // setTimeout(() => {
        //   setRedirect(true);
        // }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        // setTimeout(() => {
        //   setRedirect(true);
        // }, 3000);
      }
    })


    // } catch (error) {
    //   console.error("Failed to fetch transaction details:", error);
    // } finally {
    //   setIsFetchingMessages(false);
    // }
    // setTimeout(() => {
    //   setIsFetchingMessages(false);
    // }, 1000);
  };


  const sendMessage = (guid = "") => {
    setIsSending(true)
    const params = {
      text: messageText,
      fileId: guid
    }
    axios.post(`/cap/api/v1/dispute/${disputeId}/message`, { ...params })
      .then((result) => {
        const { data, status } = result;
        setFetchMessages(true)
        setMessageText("");
        setImage({
          src: null,
          name: "",
          allFile: null
        });

        setIsSending(false)
        if (status === 200 || status === 201) {
          if (data.result === "sent") {
            fetchDisputeMessages()
            enqueueSnackbar(t("Sent"), { variant: "success" });
          } else if (data.result === "dispute_not_found") {
            enqueueSnackbar(t("Dispute_not_found"), { variant: "error" });
          } else if (data.result === "wrong_dispute_status") {
            enqueueSnackbar(t("Wrong_dispute_status"), { variant: "error" });
          } else if (data.result === "file_not_found") {
            enqueueSnackbar(t("File_not_found"), { variant: "error" });
          }

        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }

        const element = document.getElementById('messagesContainer')
        const bodyRect = document.body.getBoundingClientRect()
        const elemRect = element.getBoundingClientRect()
        const needScrollToMyDivInPX = elemRect.top - bodyRect.top

        window.scrollTo({
          top: needScrollToMyDivInPX,
          behavior: 'smooth',
        })

      })
  }


  useEffect(() => {
    if (disputeDetail.disputeStatus === "discussion") {
      const intervalId = setInterval(fetchDisputeMessages, 5000);
      return () => clearInterval(intervalId);
    } else {
      fetchDisputeMessages()
    }
  }, [disputeDetail]);


  const navBarTitle = `${disputeDetail.title}`;

  const breadcrumbs = {
    step1Label: t("Disputs"),
    step1Url: "/app/disputs",
    step2Label: `#${disputeId}`,
  };
  console.log("disputeDetail", disputeDetail)

  const renderMessages = () => {
    const content = disputeMessages.length > 0 ? renderDisputeMessages() : renderEmptyMessagesArea();

    return content;
  }

  const renderEmptyMessagesArea = () => {
    return (
      <div className={styles.no_messages}>
        <DisputsIcon />
        {t('Star_messages_with_merchant')}
      </div>
    )
  }


  const onUploadFile = async () => {
    setIsSending(true)
    const formData = new FormData();
    formData.append('file', fullFile);


    // setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "post",
        `/cap/api/v1/file`,
        formData
      );

      if (status === 200 || status === 201) {
        const {
          data
        } = response;
        sendMessage(data);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch merchants:", error);
    } finally {
      setIsSending(false);
    }


    // axios.post(`/cap/api/v1/file`, formData)
    //   .then((result) => {
    //     const { data, status } = result;
    //     if (status === 200 || status === 201) {

    //       sendMessage(data)

    //     } else if (status === 400) {
    //       enqueueSnackbar(t("Error_400"), { variant: "error" });
    //     } else if (status === 401) {
    //       enqueueSnackbar(t("Error_401"), { variant: "error" });
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 3000);
    //     } else if (status === 404) {
    //       enqueueSnackbar(t("Error_404"), { variant: "error" });
    //       setTimeout(() => {
    //         setRedirect(true);
    //       }, 3000);
    //     } else if (status === 500) {
    //       enqueueSnackbar(t("Error_500"), { variant: "error" });
    //       setTimeout(() => {
    //         setRedirect(true);
    //       }, 3000);
    //     } else if ([501, 502, 503, 504, 505].includes(status)) {
    //       enqueueSnackbar(t("Error_500"), { variant: "error" });
    //       setTimeout(() => {
    //         setRedirect(true);
    //       }, 3000);
    //     }
    //   })

  }

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {

        cb(reader.result);
      };

      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    getBase64(file, (result) => {

      setImage({
        name: file.name,
        src: result,
        allFile: file
      });
    });
  };

  const onFileUpload = (event) => {
    const file = event.target.files[0];
    let isValid = isImageValid(file, t, enqueueSnackbar);
    if (isValid) {
      setFullFile(file)
      event && handleFileUpload(event);
    }
  };

  const renderInputContainer = () => {
    return (
      <div className={styles.input_container}>
        <div className={styles.input_entered_messages}>
          {!image.src && <Button
            component="label"
            className={classNames({
              [styles.attach_logo]: true,
              [styles.disable_attach]: disputeDetail.disputeStatus !== "discussion",
            })}
            disabled={disputeDetail.disputeStatus !== "discussion"}
          >
            <AttachFileClipIcon />
            <input
              type="file"
              hidden
              onChange={(e) => onFileUpload(e)}
            />
          </Button>}
          <input
            type="text"
            placeholder={t("Start_typing")}
            autoFocus
            onChange={(e) => setMessageText(e.target.value)}
            className={classNames({
              [styles.disabled_send_message]: disputeDetail.disputeStatus === "closed",
              [styles.colors]: messageText.length === 0,
            })}
            value={messageText}
            disabled={disputeDetail.disputeStatus !== "discussion" || isSending}
          />
          {isSending ? <CircularProgress size={18} /> :
            <SendIcon onClick={() => messageText.length === 0 || isSending ? {} : image.name ? onUploadFile() : sendMessage()}
              className={classNames({
                [styles.disabled_send_message]: messageText.length === 0 || disputeDetail.disputeStatus === "closed" || isSending,
              })}
            />}
        </div>
        {image.src && <div
          className={classNames({
            [`${styles.settings_block_description}`]: true,
            [`${styles.additional_padding}`]: true,
            [`${styles.logo_background}`]: true,
          })}
        >
          <div>
            {image.name}
          </div>

          {image.src && (
            <div className="image-info">

              <CloseIcon
                onClick={() => {
                  setImage({ src: "", name: "", allFile: null })
                }}
                sx={{ color: "#606060", cursor: "pointer" }}
              />
            </div>
          )}
        </div>}
      </div>
    )
  }


  const onAssignToBank = () => {
    setModalFetching(true)

    axios.post(`/cap/api/v1/dispute/${disputeId}/assign/bank`)
      .then((res) => {
        setModalFetching(false)
        const { data, status } = res;
        // - assigned
        // - dispute_not_found
        // - wrong_dispute_status

        if (status === 200 || status === 201) {
          if (data.result === "assigned") {
            enqueueSnackbar(t("Dispute_assigned"), { variant: "success" });
            setAssignToMerchantModal(false);
            setTimeout(() => {
              window.location.reload()
            }, 1000);
          } else if (data.result === "dispute_not_found") {
            enqueueSnackbar(t("Dispute_not_found"), { variant: "error" });
          } else if (data.result === "wrong_dispute_status") {
            enqueueSnackbar(t("Wrong_dispute_status"), { variant: "error" });
          }

        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }




      })
  }


  const renderAssignMerchantModal = () => {
    return (
      <Dialog
        open={assignToMerchantModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAssignToMerchantModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Confirm_merchant_assigning")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Are_you_sure_to_assign_bank")}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => onAssignToBank()}
              variant="contained"
              disabled={false}
              className={classNames({
                [`${styles.cancel_button}`]: true,
                [`${styles.is_disabled}`]: modalFetching,
              })}
            >
              {t("Assign_to_bank")}
              {modalFetching && (
                <CircularProgress className="circular-progress" size={18} />
              )}
            </Button>
            <Button
              onClick={() => setAssignToMerchantModal(false)}
              variant="text"
              className={styles.exit_button}
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }}>
      <div>
        {redirect && <Redirect to={"/app/disputs/"} />}
        {renderAssignMerchantModal()}
        <div
          className={classNames({
            // [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <div className={styles.forms_wrapper}>
              <div className={styles.info_block}>
                <div className={styles.info_block_title}>
                  <span>{t("General")}</span>
                  {disputeDetail.whenCreated && (
                    <span className={styles.creation_date}>
                      {t("Creation_date")}:{" "}
                      {moment(disputeDetail.whenCreated).format("DD/MM/YYYY")}
                    </span>
                  )}
                </div>
                <div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Disp_Id")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.disputeId}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Disp_title")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.title}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Status")}</label>
                    <div className={styles.block_value}>
                      {UseErrorLabel(disputeDetail.disputeStatus, t)}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Disp_assigned_on")}</label>
                    <div className={styles.block_value}>
                      {UseErrorLabel(disputeDetail.assignedTo, t)}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("MIA_disp_Id_title")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.externalDisputeId ? disputeDetail.externalDisputeId : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Category")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.categoryDescription
                        ? disputeDetail.categoryDescription
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Reason")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.reasonDescription
                        ? disputeDetail.reasonDescription
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Description")}</label>
                    <div className={styles.block_value_text}>
                      {disputeDetail.disputeDescription
                        ? disputeDetail.disputeDescription
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Swift_message_ID")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.paymentInfo.swiftMessageId
                        ? disputeDetail.paymentInfo.swiftMessageId
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Swift_message_type")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.paymentInfo.swiftMessageType
                        ? disputeDetail.paymentInfo.swiftMessageType
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Amount_MDL")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.paymentInfo.amount
                        ? disputeDetail.paymentInfo.amount
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("CCY")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.paymentInfo.ccy
                        ? disputeDetail.paymentInfo.ccy
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("MIA_POS_tx_ID_title")}</label>
                    <div className={styles.block_value}>
                      {disputeDetail.paymentInfo.txId
                        ? disputeDetail.paymentInfo.txId
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames({
                  [styles.info_block]: true,
                  [styles.info_block_messenger]: true,
                })}>
                <div className={styles.messenger_info_block_title}>
                  <div className={styles.messenger_title_name_wrapper}>
                    <span className={styles.messenger_title}>{t("Bank")}</span>
                    <span className={styles.messenger_title_name}>
                      {disputeDetail.merchantName}
                    </span>
                  </div>


                  <div className={styles.messenger_container_actions_buttons}>

                    {disputeDetail.assignedTo !== "bank" && disputeDetail.disputeStatus === "discussion" && <Button
                      variant='contained'
                      className={styles.assign_to_merchant}
                      onClick={() => setAssignToMerchantModal(true)}
                    >
                      {t("Assign_to_bank_btn")}
                    </Button>}

                  </div>

                </div>

                <div
                  className={classNames({
                    [styles.messages_content]: true,
                    [styles.messages_content_no_messages]: !showChat,
                  })}
                  id="messagesContainer"
                >
                  {!showChat ? <CircularProgress size={36} /> : renderMessages()}
                </div>
                {renderInputContainer()}

              </div>
            </div>
          )}
        </div>
      </div>
    </AppMainLayout>
  );
};

export default DisputDetails;
