import {
    CHANGE_LANGUAGE,
} from "../actions";
import { getPrefLang, isPrefLangPresent } from "../services/Prefs";

const initialState = {
    lng: isPrefLangPresent() ? getPrefLang() : "ro",
};

export default function PrefsReducer (state = initialState, action) {
    switch (action.type) {
        case CHANGE_LANGUAGE: {
            return {
                ...state,
                lng: action.payload
            }
        }

        default:
            return state;
    }
}