import React, { useState, useEffect } from 'react';
import { ISettingsModalProps } from "./types"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Button } from 'finergy-kit';
import { CloseRemoveIcon } from '../../../../svg-icons/icon-close-remove';
import { useTranslation } from 'react-i18next';
import classNames from "classnames"
// import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { LanguageIcon } from "finergy-kit"
import { changeLanguage } from '../../../../api/actionCreators/mainActionCreator';
import { useDispatch, useSelector } from 'react-redux';
// import InputAdornment from '@mui/material/InputAdornment';
// import OutlinedInput from '@mui/material/OutlinedInput';
import { getSettingsState } from '../../../../api/selectors/mainSelector';
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentLanguage } from "../../../../api/selectors/languageSelector";
import { setMaintenanceModeStatus } from "../../../../api/services/Auth"
import axios from "../../../../api/services/Api";
import { setModeStatus } from '../../../../api/actionCreators/authActionCreator';

import styles from './settings-modal.module.scss';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const SettingsModal = ({ showSettingsModal, setShowSettingsModal }: ISettingsModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const settings = useSelector(getSettingsState);
    const language = useSelector(getCurrentLanguage);

    const initSettingState = { activeServiceMode: false, currentLang: "", comissionForBuy: "", comissionForSale: "" }

    const [activeTab, setActiveTab] = useState("common")
    const [serviceModeActive, setServiceModeActive] = useState(false)
    const [isServiceModeFetching, setIsServiceModeFetching] = useState(false)
    const [isSaveSettingsFetching, setIsSaveSettingsFetching] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState(language)
    const [settingsLocalState, setSettingsLocalState] = useState({ ...initSettingState })

    // const getSettings = () => {
    //     axios
    //         .get(
    //             `/settings`
    //         )
    //         .then((res) => {
    //             const { data } = res;
    //             const obj = {
    //                 activeServiceMode: data?.isMaintenanceMode,
    //                 comissionForBuy: data?.buyCommission || "",
    //                 comissionForSale: data?.sellCommission || "",
    //             }
    //             dispatch(saveSettings(obj))
    //         })
    // }

    useEffect(() => {
        setSettingsLocalState({ ...settings })
    }, [settings])

    useEffect(() => {
      //  getSettings()
        !showSettingsModal && setActiveTab("common")
    }, [showSettingsModal])

    const handleLanguageChange = () => {
        dispatch(changeLanguage(selectedLanguage));
        setShowSettingsModal(false)
    };

    const setActiveServiceMode = () => {
        setIsServiceModeFetching(true)
        changeLocalSettingsState("activeServiceMode", true, false)
        setServiceModeActive(false)
        setIsServiceModeFetching(false)
        setActiveTab("modes")
        saveSettinsState(true)

    }

    const changeLocalSettingsState = (state, value, ignore = true) => {

        if (state === "activeServiceMode" && value && ignore) {
            setServiceModeActive(true)
        } else {
            setSettingsLocalState({
                ...settingsLocalState,
                [state]: value
            })
        }
    }

    const saveSettinsState = (serviceValue = null) => {
        setIsSaveSettingsFetching(true)
        const { activeServiceMode, comissionForBuy, comissionForSale } = settingsLocalState
        const mode = serviceValue !== null ? serviceValue : activeServiceMode

        const params = {
            isMaintenanceMode: mode,
            buyCommission: comissionForBuy,
            sellCommission: comissionForSale,
        }

        axios.put(`/settings`, { ...params })
            .then(() => {
                setMaintenanceModeStatus(mode)
                dispatch(setModeStatus(mode))
                setShowSettingsModal(false)
                setIsSaveSettingsFetching(false)
                setActiveTab("common")
            })
    }

    const resetServiceMode = () => {
        setSelectedLanguage("ru")
        setActiveTab("modes")
        setServiceModeActive(false)
        changeLocalSettingsState("activeServiceMode", false)
        setShowSettingsModal(true)
        
    }

    const onCloseModal = () => {
        setSelectedLanguage(language)
        setServiceModeActive(false)
    }

    const renderServiceModeModal = () => {
        return (
            <Dialog
                open={serviceModeActive}
                TransitionComponent={Transition}
                keepMounted
                className={styles.service_modal}
                onClose={() => onCloseModal()}
                aria-describedby="alert-dialog-slide-description"

            >
                <div className={styles.custom_modal_container}>
                    <CloseRemoveIcon className={styles.close_icon} onClick={() => onCloseModal()} />
                    <DialogTitle className={styles.setting_modal_title}>

                        {t('Turn_on_service_mode')}
                    </DialogTitle>
                    <DialogContent className={styles.modal_content}>
                        <span>
                            {t("Are_you_sure_to_turn_on_service_mode")}
                        </span>
                    </DialogContent>
                    <DialogActions className={styles.action_buttons}>
                        <Button
                            onClick={() => setActiveServiceMode()}
                            variant='secondary'
                            label={t('Mode_on')}
                            disabled={isServiceModeFetching}
                            className={styles.cancel_button}
                            size="l"
                            iconPosition="off"
                            iconOnly={isServiceModeFetching}
                            icon={isServiceModeFetching && <CircularProgress size={20} />}
                            id={"ON_SERVICE_MODE"}
                        />
                        <Button
                            onClick={() => resetServiceMode()}
                            variant='tertiary'
                            label={t("Back")}
                            size="l"
                            iconPosition="off"
                            id={"BACK_BUTTON"}
                        />
                    </DialogActions>
                </div>

            </Dialog>
        )
    }

    const resetSettingsData = () => {
        setActiveTab("common")
        setShowSettingsModal(false)
        setSettingsLocalState({ ...settings })
    }

    // const switchMode = (value) => {
    //     changeLocalSettingsState("activeServiceMode", value)
    //     !value && saveSettinsState(false)
    //     setShowSettingsModal(false)
    // }

    const isSomeChanges = selectedLanguage === language

    const renderMainDialogContent = () => {
        return (
            <Dialog
                open={showSettingsModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowSettingsModal(false)}
                aria-describedby="alert-dialog-slide-description"

            >
                <div className={classNames({
                    [`${styles.custom_modal_container}`]: true,
                    [`${styles.custom_modal_container_min_height}`]: true,
                })}
                >
                    <CloseRemoveIcon className={styles.close_icon} onClick={() => setShowSettingsModal(false)} />
                    <DialogTitle className={styles.setting_modal_title}>{t('Settings')}</DialogTitle>
                    <DialogContent className={styles.modal_content}>
                       
                        <div className={styles.tabs_content}>
                            <div className={classNames({
                                [`${styles.tab_content_item}`]: true,
                                [`${styles.active_content}`]: activeTab === "common",
                            })}
                            >
                                <div className={styles.settings_block}>
                                    <div className={styles.settings_block_title}>
                                        {t('Language')}
                                    </div>
                                    <div className={styles.settings_block_content}>

                                        <div className={styles.settings_block_description}>
                                            <div className={styles.settings_content_block_title}>
                                                {t('System_language')}
                                            </div>


                                            <div
                                                className={classNames({
                                                    [`${styles.description_action}`]: true,
                                                    [`${styles.select_lang_container_parent}`]: true,
                                                })}
                                            >
                                                <FormControl className={styles.select_lang_container}>
                                                    <LanguageIcon />
                                                    <Select
                                                        className="select-lang-selector"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectedLanguage}
                                                        onChange={(e) => setSelectedLanguage(e.target.value)}
                                                    >
                                                        <MenuItem value={"ro"} key={0}>RO</MenuItem>
                                                        <MenuItem value={"ru"} key={1}>RU</MenuItem>
                                                        <MenuItem value={"en"} key={2}>EN</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={styles.action_buttons}>
                        {activeTab === "common" && <Button
                            onClick={() => !isSomeChanges ? handleLanguageChange() : {}}
                            variant='secondary'
                            label={t('Apply')}
                            disabled={isSomeChanges}
                            className={classNames({
                                [`${styles.cancel_button}`]: !isSomeChanges,
                                [`${styles.is_disabled}`]: isSomeChanges,
                            })}

                            size="l"
                            iconPosition="off"
                            iconOnly={isSaveSettingsFetching}
                            icon={isSaveSettingsFetching && <CircularProgress size={20} />}
                            id={"ACCEPT"}
                        />}
                        <Button
                            onClick={() => resetSettingsData()}
                            variant='tertiary'
                            label={t('Cancel')}
                            size="l"
                            iconPosition="off"
                            id={"CANCEL_BUTTON"}
                        />
                    </DialogActions>
                </div>

            </Dialog>
        )
    }

    return (
        <>
            {renderServiceModeModal()}
            {renderMainDialogContent()}
        </>
    );
};

export default SettingsModal;
