// import { DashboardIcon } from "../../svg-icons/icon-dashboard"
import { CustomersIcon } from "../../svg-icons/icon-customers"
import { PersonIcon } from "../../svg-icons/icon-person"
import { PhoneSettingsIcon } from "../../svg-icons/icon-phone-settings"
import {PaymentsIcon } from "../../svg-icons/icon-payments"
import { StoreIcon } from "../../svg-icons/icon-places"
// import { ListIcon } from "../../svg-icons/icon-list"
import { TwoArrowsIcon } from "../../svg-icons/icon-two-arrows"
import { DisputsIcon } from "../../svg-icons/icon-disputs"

export const CUSTOMER_TABS = {
    info: { label: "Info", value: "info" },
    operations: { label: "Operations", value: "operations" },
    api: { label: "Api", value: "api" },
}
export const SEC_BY_DEFAULT = 100
export const SEC_BY_DEFAULT_HALF = 50
export const SEC_FOR_TIMEOUT = 20
export const PARENT_LIST = {
    general: { title: "General", value: "general" },
    users: { title: "Users", value: "users" },
    counts: { title: "Counts", value: "counts" },
    commision: { title: "Commision", value: "commision" },
}

export const ADAPTIVE_WIDTH = 1200
export const paginationSize = [5, 10, 20, 50]

export const currensyList = ["USD", "EUR", "MDL"]
export const fieldsCounts = {
    values: {
        countNumber: "",
        countCurrency: "",
    },
    fieldsList: [
        {
            inputLabel: "Count_number",
            stateName: "countNumber",
            type: "text",
            multiLine: false,
            placeholder: "456321...",
            isRequired: false,
            variant: "outlined"
        },
        {
            inputLabel: "Count_currency",
            stateName: "countCurrency",
            type: "select",
            multiLine: false,
            placeholder: "",
            isRequired: false,
            variant: "outlined",
            options: ["USD", "EUR", "MDL"]
        },
    ]
}



export const fieldsGeneral = {
    values: {
        idno: "",
        companyName: "",
        description: "",
    },
    fieldsList: [
        {
            inputLabel: "Company_name",
            stateName: "companyName",
            type: "text",
            multiLine: false,
            placeholder: "BNM...",
            isRequired: true,
        },
        {
            inputLabel: "IDNO",
            stateName: "idno",
            type: "text",
            multiLine: false,
            placeholder: "12515165...",
            isRequired: false,
            variant: "outlined",
        },
        {
            inputLabel: "Comments_optional",
            stateName: "description",
            type: "text",
            multiLine: true,
            placeholder: "Комментарий",
            isRequired: true,
        },
    ]
}

export const fieldsCommision = {
    values: {
        buyCommission: 0,
        sellCommission: 0,
    },
    fieldsList: [
        {
            inputLabel: "Buy_commision",
            stateName: "buyCommission",
            type: "text",
            multiLine: false,
            placeholder: "0.7",
            isRequired: true,
        },
        {
            inputLabel: "Sell_commision",
            stateName: "sellCommission",
            type: "text",
            multiLine: false,
            placeholder: "0.8",
            isRequired: false,
        },
    ]
}


export const fieldsUser = {
    values: {
        directorNameSurname: "",
        directorEmail: "",
        directorIdnp: "",
        directorPhone: "",
        directorId: 0,

    },
    fieldsList: [
        {
            inputLabel: "Name_surname",
            stateName: "directorNameSurname",
            type: "text",
            multiLine: false,
            placeholder: "Mark Culai...",
            isRequired: false,
            variant: "outlined"
        },
        {
            inputLabel: "IDNP",
            stateName: "directorIdnp",
            type: "text",
            multiLine: false,
            placeholder: "45656985...",
            isRequired: false,
            variant: "outlined"
        },
        {
            inputLabel: "Email",
            stateName: "directorEmail",
            type: "text",
            multiLine: false,
            placeholder: "example@gmail.com...",
            isRequired: false,
            variant: "outlined",
            disabled: true
        },
        {
            inputLabel: "Phone",
            stateName: "directorPhone",
            type: "text",
            multiLine: false,
            placeholder: "+373 68 584 828...",
            isRequired: false,
            variant: "outlined"
        },

    ]
}



export const fieldsBillingInfo = {
    values: {
        directorNameSurname: "",
        function: "",
        billingAccount: "",
        billingEmail: "",
        billingPhone: "",
    },
    fieldsList: [
        {
            inputLabel: "Contact person",
            stateName: "directorNameSurname",
            type: "text",
            multiLine: false,
            placeholder: "Natasha Romanoc...",
            isRequired: false,
            variant: "outlined"
        },
        {
            inputLabel: "Function",
            stateName: "function",
            type: "text",
            multiLine: false,
            placeholder: "Accountant...",
            isRequired: false,
            variant: "outlined"
        },
        {
            inputLabel: "Billing account",
            stateName: "billingAccount",
            type: "text",
            multiLine: false,
            placeholder: "1234 5678 9658 2563...",
            isRequired: false,
            variant: "outlined"
        },
        {
            inputLabel: "Email",
            stateName: "billingEmail",
            type: "text",
            multiLine: false,
            placeholder: "example@gmail.com...",
            isRequired: false,
            variant: "outlined"
        },
        {
            inputLabel: "Phone",
            stateName: "billingPhone",
            type: "text",
            multiLine: false,
            placeholder: "+373 68 584 828...",
            isRequired: false,
            variant: "outlined"
        },
    ]
}



export const navigation = [
    {
        linkTitle: 'Transactions',
        local: "Transactions",
        url: "/app/transactions",
        icon: <TwoArrowsIcon />,
        forRole: ["admin"]
    },

    {
        linkTitle: 'Stores',
        local: "Stores",
        url: "/app/stores",
        icon: <StoreIcon />,
        forRole: ["admin"]
    },
    // {
    //     linkTitle: 'Dashboard',
    //     url: "/dashboard",
    //     icon: <DashboardIcon />,
    //     forRole: ["admin"]
    // },
    {
        linkTitle: 'Sellers',
        local: "Sellers",
        url: "/app/sellers",
        icon: <PersonIcon />,
        forRole: ["admin"]
    },
    {
        linkTitle: 'Terminals',
        local: "Terminals",
        url: "/app/terminals",
        icon: <PhoneSettingsIcon />,
        forRole: ["admin"]
    },
    {
        linkTitle: 'Disputs',
        local: "Disputs",
        url: "/app/disputs",
        icon: <DisputsIcon />,
        forRole: ["admin"],
        showNotification: true
    },
    {
        linkTitle: 'Request to Pay',
        local: "Request_to_pay",
        url: "/app/requests",
        icon: <PaymentsIcon />,
        forRole: ["admin"]
    },

];

export const data = [
    {
        name: 'Page A',
        uv: 590,
        pv: 800,
        amt: 1400,
    },
    {
        name: 'Page B',
        uv: 868,
        pv: 967,
        amt: 1506,
    },
    {
        name: 'Page C',
        uv: 1397,
        pv: 1098,
        amt: 989,
    },
    {
        name: 'Page D',
        uv: 1480,
        pv: 1200,
        amt: 1228,
    },
    {
        name: 'Page E',
        uv: 1520,
        pv: 1108,
        amt: 1100,
    },
    {
        name: 'Page F',
        uv: 1400,
        pv: 680,
        amt: 1700,
    },
];

export const mockCustomersData = {
    columns: [
        { field: 'id', headerName: '#', flex: 1, },
        // { field: 'companyId', headerName: '#', flex: 1, },
        { field: 'idno', headerName: 'IDNO', flex: 1, },
        { field: 'companyName', headerName: 'Company name', flex: 1, },
        { field: 'directorEmail', headerName: 'Email', flex: 1, },
        { field: 'directorPhone', headerName: 'Phone', flex: 1, },
        { field: 'whenCreated', headerName: 'When created', flex: 1, },
    ],
}

export const mockCustomerLicensesData = {}
export const mockKycData = {
    rows: [
        {
            id: 1,
            name: 'Dragan Alexandru',
            idnp: "6786585687",
            verifiedBy: "Jora Bardiur1",
            status: "received",
            registrationDate: "23/11/2023",
        },
        {
            id: 2,
            name: 'Dragan Alexandru',
            idnp: "546756788568",
            verifiedBy: "Jora Bardiur2",
            status: "error",
            registrationDate: "12/12/2023",
        },
        {
            id: 3,
            name: 'Dragan Alexandru',
            idnp: "1778617414",
            verifiedBy: "Jora Bardiur3",
            status: "filled",
            registrationDate: "17/05/2023",
        },
        {
            id: 4,
            name: 'Dragan Alexandru',
            idnp: "1202506405604",
            verifiedBy: "Jora Bardiur4",
            status: "filling",
            registrationDate: "23/08/2023",
        },

    ]
}
export const mockLicensesData = {
    
}

export const mockCustomerOperationData = {
    rows: [
        {
            id: 1,
            name: 'Dragan Alexandru',
            idnp: "6598659136",
            registrationDate: "09-08-2023",
            approvedBy: "Steven Sigal",
            status: "error",
            errorDetails: {
                location: "KYC form, step 2: SMS Portal",
                process: "SMS Error",
                dateTime: "08:52, 02/09/23"
            }
        },

        {
            id: 2,
            name: 'Lia Taburceanu',
            idnp: "465673756756",
            registrationDate: "09-08-2023",
            approvedBy: "Jackie Chan",
            status: "filled",
        },

        {
            id: 3,
            name: 'Marin Tarina',
            idnp: "87126541654",
            registrationDate: "09-08-2023",
            approvedBy: "Pamela Anderson",
            status: "bank received",
        },

        {
            id: 4,
            name: 'Nicolae Cernei',
            idnp: "65715426862",
            registrationDate: "09-08-2023",
            approvedBy: "Britney Spears",
            status: "filled",
        },

        {
            id: 5,
            name: 'Dragan Alexandru',
            idnp: "5685679854875478",
            registrationDate: "09-08-2023",
            approvedBy: "John Travolta",
            status: "filling",
        },

        {
            id: 6,
            name: "Marin Tarina",
            idnp: "6789648576436",
            registrationDate: "09-08-2023",
            approvedBy: "Ibragin Tsalalalagov",
            status: "error", errorDetails: {
                location: "KYC form, step 8: Save data",
                process: "No response from BFF to save data",
                dateTime: "11:34, 05/10/23"
            }
        },

        {
            id: 7,
            name: 'Nicolae Cernei',
            idnp: "7612462462",
            registrationDate: "09-08-2023",
            approvedBy: "Curt Russel",
            status: "bank received",
        },

        {
            id: 8,
            name: 'Jora Bukin',
            idnp: "561431453143",
            registrationDate: "09-08-2023",
            approvedBy: "Fedor Emelianenko",
            status: "error",
            errorDetails: {
                location: "KYC form, step 4: Education",
                process: "No response from ASP",
                dateTime: "08:52, 02/09/23"
            }
        },

        {
            id: 9,
            name: 'Fedor Tralala',
            idnp: "475156315613563",
            registrationDate: "09-08-2023",
            approvedBy: "Steven Sigal",
            status: "error",
            errorDetails: {
                location: "KYC form, step 2: Face ID verification",
                process: "No response from ASP",
                dateTime: "08:52, 02/09/23"
            }
        },

    ]
}


